<template>
  <section class="dtc-main-section">
    <div>
      <h4>衛生福利部補助計畫收支明細表</h4>
      <b-input-group prepend="受補助單位：">
        <b-input />
      </b-input-group>
      <b-input-group prepend="補助年度：">
        <b-input />
      </b-input-group>
      <b-input-group prepend="計畫名稱：">
        <b-input style="max-width:60px" class="ml-4" />
        <div class="pl-2 pr-3 pt-2">學年度第</div>
        <b-input style="max-width:60px" />
        <div class="pl-2 pr-3 pt-2">學期「原住民及離島地區醫事人員養成計畫」</div>
      </b-input-group>
    </div>
    <div class="dtc-grid4">
      <div class="dtc-title dtc-title2">
        <div class="dtc-line"></div>
        <div style="margin-left:60px;margin-top:30px;">核撥(結報)</div>
        <div style="margin-left:30px;">經費預算核撥數</div>
      </div>
      <div class="dtc-title dtc-row-3">
        <div class="dtc-input-cell">
          <div>第一次核撥日期</div>
          <input type="date" class="p1-2" />
          <div>金額</div>
          <!-- <input type="number" /> -->
          <div></div>
          <div>$</div>
          <div>
            <input type="number" class="d-inline-block mr-2" style="max-width:120px" />元
          </div>
        </div>
        <div></div>
        <div class="dtc-input-cell">
          <div>第一次結報日期</div>
          <input type="date" class="p1-2" />
          <div>金額</div>
          <!-- <input type="number" /> -->
          <div></div>
          <div>$</div>
          <div>
            <input type="number" class="d-inline-block mr-2" style="max-width:120px" />元
          </div>
        </div>
      </div>
      <div class="dtc-title dtc-row-3">
        <div class="dtc-input-cell">
          <div>第二次核撥日期</div>
          <input type="date" class="p1-2" />
          <div>金額</div>
          <!-- <input type="number" /> -->
          <div></div>
          <div>$</div>
          <div>
            <input type="number" class="d-inline-block mr-2" style="max-width:120px" />元
          </div>
        </div>
        <div class="dtc-input-cell">
          <div>第一次餘(絀)數</div>
          <input type="date" class="p1-2" />
          <div>金額</div>
          <!-- <input type="number" /> -->
          <div></div>
          <div>$</div>
          <div>
            <input type="number" class="d-inline-block mr-2" style="max-width:120px" />元
          </div>
        </div>
        <div class="dtc-input-cell">
          <div>第二次結報日期</div>
          <input type="date" class="p1-2" />
          <div>金額</div>
          <!-- <input type="number" /> -->
          <div></div>
          <div>$</div>
          <div>
            <input type="number" class="d-inline-block mr-2" style="max-width:120px" />元
          </div>
        </div>
      </div>
      <div class="dtc-title dtc-row-3">
        <div></div>
        <div class="dtc-input-cell">
          <div>第二次餘(絀)數</div>
          <input type="date" class="p1-2" />
          <div>金額</div>
          <!-- <input type="number" /> -->
          <div></div>
          <div>$</div>
          <div>
            <input type="number" class="d-inline-block mr-2" style="max-width:120px" />元
          </div>
        </div>
        <div></div>
      </div>
    </div>
    <div class="dtc-grid4 dtc-grid-row">
      <div class="dtc-title">業務費</div>
      <div class="dtc-title dtc-row-3"></div>
      <div class="dtc-title dtc-row-3"></div>
      <div class="dtc-title dtc-row-3"></div>
    </div>
    <div class="dtc-grid4 dtc-grid-row">
      <div class="dtc-title">-公費生受領待遇補助費</div>
      <div class="dtc-title dtc-row-3"></div>
      <div class="dtc-title dtc-row-3"></div>
      <div class="dtc-title dtc-row-3"></div>
    </div>
    <div class="dtc-grid4 dtc-grid-row">
      <div class="dtc-title">設備費</div>
      <div class="dtc-title dtc-row-3"></div>
      <div class="dtc-title dtc-row-3"></div>
      <div class="dtc-title dtc-row-3"></div>
    </div>
    <div class="dtc-grid4 dtc-grid-row">
      <div class="dtc-title"></div>
      <div class="dtc-title dtc-row-3"></div>
      <div class="dtc-title dtc-row-3"></div>
      <div class="dtc-title dtc-row-3"></div>
    </div>
    <div class="dtc-grid4 dtc-grid-row">
      <div class="dtc-title"></div>
      <div class="dtc-title dtc-row-3"></div>
      <div class="dtc-title dtc-row-3"></div>
      <div class="dtc-title dtc-row-3"></div>
    </div>
    <div class="dtc-grid4 dtc-grid-row">
      <div class="dtc-title"></div>
      <div class="dtc-title dtc-row-3"></div>
      <div class="dtc-title dtc-row-3"></div>
      <div class="dtc-title dtc-row-3"></div>
    </div>
    <div class="dtc-grid4 dtc-grid-row">
      <div class="dtc-title">小計</div>
      <div class="dtc-title dtc-row-3"></div>
      <div class="dtc-title dtc-row-3"></div>
      <div class="dtc-title dtc-row-3"></div>
    </div>
    <div class="dtc-grid4 dtc-grid-row">
      <div class="dtc-title">餘（絀）數</div>
      <div class="dtc-title dtc-row-3"></div>
      <div class="dtc-title dtc-row-3"></div>
      <div class="dtc-title dtc-row-3"></div>
    </div>

    <div class="dtc-grid4 dtc-grid-row">
      <div class="dtc-title" style="height:140px">備註</div>
      <div class="dtc-title dtc-row-3 dtc-note" style="grid-column: span 3;height:140px">
        <div class="pl-5 pt-5">
          <span>1.繳回款：經常門＄</span>
          <input type="number" style="width:230px" />元
          <span>資本門＄</span>
          <input type="number" style="width:230px" />元
        </div>
        <div class="pl-5 pt-3">
          <span>
            2.其他衍生收入：＄
            <input type="number" style="width:230px" /> 元，請於結報時併同結餘款解繳本部。
          </span>
        </div>
      </div>
    </div>
    <footer>
      <div>製表人</div>
      <div>覆核</div>
      <div>會計人員</div>
      <div>單位首長</div>
    </footer>
  </section>
</template>

<script>
import queryString from "query-string";
export default {
  name: "doc2",
  data() {
    return {};
  },

  methods: {},
  mounted() {
    localStorage.dtcPage4Print = "2";
  },
  beforeMount() {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
footer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  // border: 1px solid white;
  padding: 5px;
  min-width: 1122px;
  > div {
    min-height: 140px;
  }
}
.dtc-note {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  //margin: 1rem;
  border: none;
  > div {
    border: none !important;
    height: 54px;
  }
}
.dtc-input-cell {
  display: grid;
  padding: 12px;
  grid-template-columns: 130px 150px;
  grid-template-rows: repeat(3, 44px);
  grid-row-gap: 12px;
  line-height: 44px;
}
.dtc-row-3 {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(3, 1fr);
  > div {
    border-bottom: 1px solid var(--dtc-border-color);
  }
  > div:last-child {
    border-bottom: none;
  }
}
.dtc-line {
  position: absolute;
  width: 2px;
  height: 100%;
  transform: rotate(-15deg);
  display: block;
  top: 0;
  left: 50%;
  right: 0;
  bottom: 0;
  background: var(--dtc-border-color);
}
.dtc-main-section {
  --dtc-border-color: var(--dark);
}
.dtc-grid4 {
  display: grid;
  border-top: none;
  min-height: 500px;
  grid-template-columns: 150px repeat(3, 1fr);
  .dtc-title {
    position: relative;
    height: 540px;
    border: 1px solid var(--dtc-border-color);
    border-bottom: 1px solid var(--dtc-border-color);
  }
  .dtc-title2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    writing-mode: vertical-lr;
  }
}
.dtc-grid-row {
  min-height: 44px;
  .dtc-title {
    height: 44px;
    padding: 5px;
    border: none;
    font-size: 13px;
    border-right: 1px solid var(--dtc-border-color);
    border-bottom: 1px solid var(--dtc-border-color);
  }
  .dtc-title:first-child {
    border-left: 1px solid var(--dtc-border-color);
  }
}
.dtc-main-section {
  display: grid;
  place-items: center;
  > div {
    display: grid;
    min-width: 1122px;
    border: 1px solid var(--dtc-border-color);
    border-bottom: none;
    border-radius: 5px;
    h4 {
      text-align: center;
      padding-top: 3px;
    }
  }
}

.input-group-prepend .input-group-text {
  min-width: 150px;
}

@media screen {
  // .input-group-prepend .input-group-text {
  //   background: var(--dark);
  //   color: var(--light);
  // }
  // .input-group-append .input-group-text {
  //   background: var(--dark);
  //   color: var(--light);
  // }
  // .input-group input,
  // input,
  // input,
  // textarea,
  // select,
  // textarea:focus .input-group select {
  //   background: var(--dark);
  //   color: var(--light);
  // }
}

@media print {
  #app .input-group-prepend .input-group-text {
    background: white;
    color: black;
  }
  #app .input-group-append .input-group-text {
    background: white;
    color: black;
  }
  #app {
    .input-group input,
    .input-group-text input,
    input,
    textarea,
    select,
    textarea:focus .input-group select {
      background: white;
      color: black;
    }
  }
}

@media print {
  .dtc-main-section {
    --dtc-border-color: var(--dark) !important;
    width: 1122px;
    position: relative;
    margin-top: 30px;
    margin: 30px auto 2px auto;
    width: 1122px;
    margin-bottom: 60px;
    color: black !important;
    > div {
      h4 {
        color: black;
      }
    }
  }
}
</style>
